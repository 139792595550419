
import {Options, Vue} from 'vue-class-component';
import ProductionTemplate from "@/components/ProductionTemplate.vue";
import ProductIntroduceData from "@/types/ProductIntroduceData";

@Options<MetaGo>({
  components: {
    ProductionTemplate
  },
  props: {}
})
export default class MetaGo extends Vue{
  introduce:ProductIntroduceData = {
    name:'MetaGo',
    profile:'数字时代的合同管理平台',
    buttons:{
      experience:'https://clm.metago.cn/clm/#/home',
      videoIntroduce:''
    },
    features:[
      {
        svgIconName: 'upload',
        feature:{
          title:'一键上传 —— 老合同或新合同，扫描件或Word文档，都可以',
          desc:[
            '用最简单的操作，实现对企业存量与新增合同的数字化，扫描件可自动转换为可编辑文本',
          ]
        }
      },
      {
        svgIconName: 'copy',
        feature:{
          title:'智能模板 —— 自助创建，按需更新',
          desc:[
            '快速根据提示完成合同起草，并可以轻松DIY合同模板',
          ]
        }
      },
      {
        svgIconName: 'group',
        feature:{
          title:'协同编辑 —— 团队成员、第三方可共同在线编辑',
          desc:[
            '团队成员在线协作，减少合同的低效流转',
            '编辑过程全留痕、可溯源，兼顾便捷高效和稳定安全',
          ]
        }
      },
      {
        svgIconName: 'circuit',
        feature:{
          title: '自动抽取 —— 关键信息，一目了然',
          desc: [
            '全面检视合同内容，快速精准突出重点，帮助用户第一时间了解每份合同的“骨架”、建立后续管理合同的“提纲”',
          ]
        }
      }
    ],
    psText:'管合同，用MetaGO'
  }
}
