
import {Options, Vue} from 'vue-class-component';
import {PropType} from "vue";
import ProductIntroduceData from "@/types/ProductIntroduceData";
import ReactScrollImage from "@/components/ReactScrollImage.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import {RefValue} from "vue/macros";
import anime from "animejs";
import { isMobile } from  "@/utils";
type ContentRows = {
  svgWrapper:HTMLElement;
  svg:SVGSVGElement;
  line: HTMLElement;
  title: HTMLElement;
  featureDescriptions: HTMLAllCollection;
}[]
@Options<ProductionTemplate>({
  components: {
    ReactScrollImage,
    SvgIcon
  },
  computed:{
    contentRows():ContentRows{
      return this.contentRowRefs.map((row:HTMLElement) => this.getContentRowKeyNodes(row));
    }
  },
  props: {
    introduce:{
      required:true,
      type: Object as PropType<ProductIntroduceData>
    },
    serverData: {
      require: false,
      type: Object as PropType<{
        qrCodeSrc: string;
        wechatUserName: string;
        email: string;
        phoneNumber: string;
      }>,
      default: {
        qrCodeSrc: require('../assets/wechat-sunwen.jpg'),
        email: 'sunwen@metasota.ai',
        wechatUserName: 'metasota01',
        phoneNumber: '18519190834'
      }
    }
  }
})
export default class ProductionTemplate extends Vue {
  introduce?: ProductIntroduceData;
  observer?: IntersectionObserver;
  contentRows?: ContentRows;

  contentRowRefs:HTMLElement[] = [];
  getContentRowRef(ref:RefValue<HTMLElement>){
    this.contentRowRefs.push(ref);
  }

  getContentRowKeyNodes(row:HTMLElement){
    const svgWrapper = row.querySelector('.left-svg-wrapper');
    const svg = svgWrapper!.querySelector('svg');
    const line = svgWrapper!.querySelector('.solid-line');
    const title = row.querySelector('.product-feature-title');
    const featureDescriptions = row.querySelectorAll('.feature-desc-snippet');

    return {
      svgWrapper,
      svg,
      line,
      title,
      featureDescriptions,
    }
  }

  mounted(){
    this.contentRows?.forEach(({svgWrapper,svg,line,title,featureDescriptions}) => {
      anime.set([svgWrapper,svg,line,title,...featureDescriptions],{
        opacity:0
      });
    });

    this.observer = new IntersectionObserver((entries,observer) => {
      for(let entry of entries){
        if(entry.intersectionRatio > (isMobile() ?  0 : .8)){
          const {svgWrapper, svg, line, title, featureDescriptions} = this.getContentRowKeyNodes(entry.target as HTMLElement);
          const tl = anime.timeline({
            easing: 'easeOutExpo',
            duration: 750
          });

          tl.add({
            targets:svgWrapper,
            opacity:{
              duration:1200,
              value:1
            },
            translateY:[60,0],
          })
          .add({
            targets:[svg,line],
            opacity:1,
            translateX:[-50,0],
            delay:function (el,index){
              return 80 * index;
            }
          },200)
          .add({
            targets:[title,...featureDescriptions],
            opacity:1,
            translateX:[-50,0],
            delay:function(el:HTMLElement,index){
              return 50 * index;
            }
          },100);

          observer.unobserve(entry.target);
        }
      }
    },{
      root:null,
      rootMargin:'0px',
      threshold:isMobile() ?  0 : .8
    });
    for(let rowRef of this.contentRowRefs){
      this.observer.observe(rowRef);
    }
  }

  beforeUpdate(){
    this.contentRowRefs = [];
  }

  unmounted(){
    this.observer?.disconnect();
  }
}
